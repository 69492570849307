import { Link } from "gatsby-plugin-modal-routing-3"
import React, { useEffect, useState } from "react"
import { getThumb, normalizeSrcset } from "../../utils"
import Picture from "../picture"

const CollectionList = ({ collections, isMobile }) => {
  const [lastItem, setLastItem] = useState(null)
  const [products, setProducts] = useState([])

  useEffect(() => {
    const products = collections.flatMap(collection => collection.products)

    setProducts(products)
  }, [collections])
  const moveImg = e => {
    if (isMobile) return
    const image = e.target.lastChild
    let x = e.clientX

    const xWithoutOverflow =
      document.documentElement.clientWidth - image.clientWidth >= x
        ? x
        : document.documentElement.clientWidth - image.clientWidth
    image.classList.remove("hidden")
    image.style.left = `${xWithoutOverflow}px`
    setLastItem(image)
  }
  const hideImage = () => {
    if (isMobile) return
    return lastItem ? lastItem.classList.add("hidden") : null
  }
  return (
    <div className={`w-full mb-22`}>
      <h1 className="text-3xl my-6 pl-5 md:text-5xl lg:mt-7 lg:relative lg:text-7.5xl lg:whitespace-nowrap">
        Catalogue
      </h1>
      {products.map((product, i) => {
        const thumbnail = getThumb(product?.assets)
        return (
          <Link
            key={i}
            onMouseMove={e => moveImg(e)}
            onMouseLeave={hideImage}
            to={`/product/${product?.name
              ?.replace(/[^A-Z0-9]+/gi, "-")
              .toLowerCase()}-${product?.productColor?.name
              .replace(/[^A-Z0-9]+/gi, "-")
              .toLowerCase()}`}
            asModal
            className=" grid grid-cols-listMobile gap-x-5 items-center py-4 px-2 text-xs md:text-base  lg:px-10 lg:text-2xl md:w-full md:grid md:grid-cols-list hover:border-black border-transparent border-b border-t relative"
          >
            <span className="col-start-2 md:col-start-1 md:row-start-1 md:text-base lg:pointer-events-none">
              ({product?.id})
            </span>
            <p className="col-start-2 pointer-events-none">{product?.name}</p>
            <p className="col-start-1  md:col-start-3 md:row-start-1 row-start-2 lg:pointer-events-none lg:whitespace-nowrap">
              {collections.map(
                item => item.products.some(e => e.id === product.id) && item.name
              )}
            </p>
            <div className="col-start-2  md:col-start-4 md:row-start-1 mt-1 lg:pl-10 pointer-events-none">
              {product?.colors?.map((color, i) =>
                i !== product?.colors.length - 1 ? (
                  <p key={i} className="inline-block pointer-events-none">
                    {color},&nbsp;
                  </p>
                ) : (
                  <p key={i} className="inline-block pointer-events-none">
                    {color}
                  </p>
                )
              )}
            </div>
            <p className="col-start-3 row-start-2 text-right whitespace-nowrap md:col-start-5 md:row-start-1 pointer-events-none">
              {product?.pricePoint ? Number(product?.pricePoint?.name)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : '--.-'}{" "}
              €
            </p>
            {!isMobile &&
              product &&
              thumbnail && (
                <div className="pointer-events-none absolute transform -translate-y-1/2 top-0 left-0 w-72 z-10 hidden">
                  <Picture
                    className={`w-full`}
                    srcset={normalizeSrcset(thumbnail?.cached)}
                    forceSizeIndex={1}
                  />
                </div>
              )}
          </Link>
        )
      })}
    </div>
  )
}

export default CollectionList
