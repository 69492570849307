import PropTypes from "prop-types"
import React, {useEffect} from "react"
import Badge from "../badge/badge"
import {Link} from "gatsby-plugin-modal-routing-3"
// import Img from "gatsby-image"
import constants from "../../constants"
import Picture from "../picture"
import {normalizeSrcset} from "../../utils"

const CollectionThumbnailItem = ({
  // id,
  title,
  price,
  link,
  // materials,
  colors,
  thumbnail,
  badge,
  className,
  slide = false
}) => {
  const color = colors
    .split(" ")
    .map(color => color[0] + color.toLowerCase().slice(1))
    .join(" ")
  const handleBorder = e => {
    if (slide) return
    const items = document.querySelectorAll("#item")
    items.forEach(item => {
      if (
        item.getBoundingClientRect().right ===
        document.documentElement.clientWidth
      ) {
        item.classList.remove("lg:border-r")
        item.classList.remove("border-r-0.5px")
      } else {
        item.classList.add("lg:border-r")
        item.classList.add("border-r-0.5px")
      }
    })
  }
  useEffect(() => {
    if (document.readyState === "complete") {
      handleBorder()
    } else {
      window.addEventListener("load", handleBorder)
      return () => document.removeEventListener("load", handleBorder)
    }
  })
  useEffect(() => {
    window.addEventListener("resize", handleBorder)
    return () => document.removeEventListener("resize", handleBorder)
  })
  const thumbmailImage = thumbnail
  const thumb = normalizeSrcset(thumbmailImage?.cached)
  return (
    <Link
      id="item"
      to={link}
      asModal
      onLoad={e => handleBorder}
      className={`bg-black border-r-0.5px border-b-0.5px border-black overflow-hidden h-halfRow lg:relative lg:border-r lg:border-b  lg:row-span-2 lg:h-row ${className}`}
    >
      <div className="h-full bg-white">
        <div className={`relative h-63% lg:h-full lg:absolute w-full bg-product-gray pt-0 lg:pt-10`}>
          {thumb[0] ? (
            <div className={`absolute right-0 bottom-0 w-full h-full bg-product-gray`}>
              <div className={`w-full h-full px-0 lg:px-10`}>
                <Picture
                  className={`object-contain w-full h-full`}
                  srcset={thumb}
                  forceSizeIndex={2}
                />
              </div>
            </div>
          ) : (
            <div className={`w-full h-full bg-product-gray`}>
              <div className={`flex lg:flex-col justify-center items-center h-full`}>
                <div className={`lg:mx-auto w-9/12 lg:w-8/12`}>
                  <p className={`text-center text-black ${constants.quote}`}>
                    Coming
                    <br />
                    soon
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="px-2 py-1 h-full text-sm bg-white lg:py-5 lg:px-2.5 lg:text-base lg:bg-transparent lg:relative lg:z-10">
          <div className="flex">
            <div className="w-full lg:w-1/2">
              <p className="lg:text-xl">{title}</p>
              <span className="block opacity-50 mt-2 ">
                {price ?
                  Number(price?.name)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  :
                  '--.-'}{" "}
                €
              </span>
            </div>
            <p className="hidden lg:block lg:pl-20">
              {color}{" "}
              {`color available`}
            </p>
          </div>
          <div className="mt-2 w-full lg:flex lg:mt-10">
            <p className="inline-block font-bold text-xs w-max lg:font-normal lg:text-base lg:opacity-50">
              Learn More
            </p>
          </div>
        </div>
        {badge && <Badge>B CONSCIOUS</Badge>}
      </div>
    </Link>
  )
}

CollectionThumbnailItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.object,
  link: PropTypes.string.isRequired,
  materials: PropTypes.array,
  colors: PropTypes.string.isRequired,
  badge: PropTypes.bool,
}

export default CollectionThumbnailItem
