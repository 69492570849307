import React from "react"
import CollectionThumbnailsGrid from "../collection-thumbnail-grid/collection-thumbnail-grid"
// import CollectionThumbnailsHeader from "../collection-thumbnail-header/collection-thumbnail-header"
const CollectionThumbnails = ({ collections, isMobile }) => {
  return (
    <div className={`w-full min-h-screen`}>
      {collections?.map((collection, i) => {
        return (
          <React.Fragment key={i}>
            {/* <CollectionThumbnailsHeader
              color={i === 0 ? "black" : "white"}
              name={collection.name.replace("Special Edition", "E")}
              number={collection.products.length}
              description={collection.description}
            /> */}
            <CollectionThumbnailsGrid
              isMobile={isMobile}
              products={collection.products}
              images={collection.editorial}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default CollectionThumbnails
