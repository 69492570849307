import React, { useEffect } from "react"
import Img from "gatsby-image"

const imageClassNames = "bg-black w-full"
const ImageCover = ({ image, text, isMobile }) => (
  <div
    id="item"
    className={`${imageClassNames} relative border-r-0.5px border-b-0.5px border-black overflow-hidden lg:border-r lg:border-b h-almostRow col-span-2 row-span-3 lg:col-span-3 lg:h-3rows`}
  >
    <div className="lg:h-full bg-white">
      <div className="absolute h-full w-full">
        <Img
          className="absolute w-full h-full left-0 top-0 object-cover"
          fluid={image}
          alt=""
        />
      </div>
      {!isMobile && (
        <div className=" absolute bottom-16 left-9 z-20 w-max text-white">
          <h4 className="text-8xl">
            <span className="text-2xl pr-2">{text?.third}</span>
            {text?.first}
          </h4>
          <h4 className="text-8xl">{text?.second}</h4>
        </div>
      )}
    </div>
  </div>
)
const ImageColumn = ({ image, text, isMobile }) => {
  return (
    <div
      id="item"
      className={`${imageClassNames} border-r-0.5px border-b-0.5px border-black overflow-hidden lg:border-r lg:border-b h-halfRow row-span-2 col-span-2 relative lg:h-auto`}
    >
      <div className="lg:h-full bg-white">
        <div className="absolute w-full h-full">
          <Img
            className="absolute w-full h-full left-0 top-0 object-cover"
            fluid={image}
            alt=""
          />
        </div>
        {!isMobile && (
          <div className=" absolute bottom-16 left-9 z-20 w-max text-white">
            <h4 className="text-6xl">
              <span className="text-base pr-2">{text?.third}</span>
              {text?.first}
            </h4>
            <h4 className="text-6xl">{text?.second}</h4>
          </div>
        )}
      </div>
    </div>
  )
}
const ImageRow = ({ image, text, isMobile }) => (
  <div
    id="item"
    className={`${imageClassNames} ${
      isMobile && "col-span-2"
    } border-r-0.5px border-b-0.5px border-black overflow-hidden lg:border-r lg:border-b h-row row-span-4 relative overflow-hidden border-black lg:border-r lg:border-b lg:h-4rows`}
  >
    <div className="lg:h-full bg-white">
      <div className="absolute w-full h-full">
        <Img
          className="absolute w-full h-full left-0 top-0 object-cover"
          fluid={image}
          alt=""
        />
      </div>
      {!isMobile && (
        <div className=" absolute bottom-16 left-9 z-20 w-max text-white">
          <h4 className="text-6xl">
            <span className="text-base pr-2">{text?.third}</span>
            {text?.first}
          </h4>
          <h4 className="text-6xl">{text?.second}</h4>
        </div>
      )}
    </div>
  </div>
)
const ImageColumnRow = ({ image, text, isMobile }) => (
  <div
    id="item"
    className={`${imageClassNames} border-r-0.5px border-b-0.5px border-black overflow-hidden h-almostRow lg:h-auto lg:border-r lg:border-b row-span-4 col-span-2 relative`}
  >
    <div className="lg:h-full bg-white">
      <div className="absolute w-full h-full">
        <Img
          className="absolute w-full h-full left-0 top-0 object-cover"
          fluid={image}
          alt=""
        />
      </div>
      {!isMobile && (
        <div className="absolute bottom-16 left-9 z-20 w-max text-white">
          <h4 className="text-6xl">
            <span className="text-base pr-2">{text?.third}</span>
            {text?.first}
          </h4>
          <h4 className="text-6xl">{text?.second}</h4>
        </div>
      )}
    </div>
  </div>
)

const EditorialImage = ({ type, id, url, text, isMobile }) => {
  const handleBorder = e => {
    const items = document.querySelectorAll("#item")
    items.forEach(item => {
      if (
        item.getBoundingClientRect().right ===
        document.documentElement.clientWidth
      ) {
        item.classList.remove("lg:border-r")
        item.classList.remove("border-r-0.5px")
      }
    })
  }
  useEffect(() => {
    if (document.readyState === "complete") {
      handleBorder()
    } else {
      window.addEventListener("load", handleBorder)
      return () => document.removeEventListener("load", handleBorder)
    }
  }, [])
  return type === "cover" ? (
    <ImageCover
      isMobile={isMobile}
      key={id}
      text={text}
      image={url.childImageSharp.fluid}
    />
  ) : type === "column" ? (
    <ImageColumn
      isMobile={isMobile}
      key={id}
      text={text}
      image={url.childImageSharp.fluid}
    />
  ) : type === "row" ? (
    <ImageRow
      isMobile={isMobile}
      key={id}
      text={text}
      image={url.childImageSharp.fluid}
    />
  ) : type === "column-row" ? (
    <ImageColumnRow
      isMobile={isMobile}
      key={id}
      text={text}
      image={url.childImageSharp.fluid}
    />
  ) : null
}

export default EditorialImage
