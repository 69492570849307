import SEO from "components/seo"
import {graphql, navigate} from "gatsby"
import {observer} from 'mobx-react';
import axios from 'axios';
import ProductStore from '../store/product';
import React, {useEffect, useState} from "react"
import CollectionList from "../components/collection-list/collection-list"
import CollectionFilter from "../components/collection-thumbnail-functionality/collection-thumbnail-functionality"
import CollectionThumbnails from "../components/collection-thumbnails/collection-thumbnails"
import Layout from "../components/layout"
import filterProducts from "../utils/filterProducts"
import generateCollectionsFromProducts from "../utils/generateCollectionProducts"

const CataloguePage = observer(({location, data}) => {
  const [pureCollections, setPureCollections] = useState([])
  const [collections, setCollections] = useState(null)
  const allProducts = ProductStore.getProducts;
  const allCollections = ProductStore.getCollections;
  const building = typeof window === "undefined"
  const [isMobile, setIsMobile] = useState(!building && window.isMobile)
  const [navigateOnce, setNavigateOnce] = useState(false);
  useEffect(() => {
    setPureCollections(
      generateCollectionsFromProducts({
        allProducts,
        allCollections,
      })
    )
  }, [allCollections, allProducts])
  useEffect(() => {
    ProductStore.fetchAll();
    const handleMobile = () => {
      if (window.innerWidth < 1024) return setIsMobile(true)
      if (window.innerWidth > 1024) return setIsMobile(false)
    }
    window.setIsMobile = () => {
      setIsMobile(window.isMobile)
    }
    if (document.readyState === "complete") {
      handleMobile()
    } else {
      window.addEventListener("load", handleMobile)
      return () => document.removeEventListener("load", handleMobile)
    }
    window.addEventListener("resize", handleMobile)
    return () => {
      window.removeEventListener("resize", handleMobile)
    }
  }, [])

  const [viewType, setViewType] = useState("thumbnails");
  const [filterOptions, setFilterOptions] = useState(null);
  const productName = location.pathname.split('/').filter((item) => item !== "").pop();
  if (productName !== 'catalogue' && !navigateOnce) {
    setNavigateOnce(true)
  }

  // allProducts.forEach(product => {
  //   console.log(product)
  // })

  useEffect(() => {
    if(navigateOnce){
      window.location.pathname = `/product/${productName}`
    }
  }, [navigateOnce])

  useEffect(() => {
    if (!filterOptions) return;

    filterProducts({
      pureCollections,
      allProducts,
      filterOptions,
      setCollections,
      urlSearch: location.search,
    });

    //console.log(pureCollections);
  }, [filterOptions, pureCollections, allProducts])

  return (
    <Layout location={location}>
      <SEO title={`Catalogue`} pathname={location.pathname} />
      <div className={`pt-[70px] lg:pt-[90px]`}>
        <CollectionFilter
          setFilterOptions={setFilterOptions}
          viewType={viewType}
          setViewType={setViewType}
        />
      </div>
      {viewType === "thumbnails" ? (
        <CollectionThumbnails
          collections={collections ? collections : pureCollections}
          isMobile={isMobile}
        />
      ) : (
        <CollectionList
          collections={collections ? collections : pureCollections}
          isMobile={isMobile}
        />
      )}
    </Layout>
  )
})

export default CataloguePage
