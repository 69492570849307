import React from "react"
import PropTypes from "prop-types"

const Badge = ({ children }) => {
  return (
    <p className="inline-block text-xs px-4 py-2 rounded-full bg-green-800 text-white absolute bottom-7 left-6">
      {children}
    </p>
  )
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Badge
