const datesInNumbers = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 5,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
}
const filterProducts = props => {
  const { filterOptions, pureCollections, setCollections, urlSearch } = props
  //console.log('filter started',{ filterOptions, pureCollections, setCollections, urlSearch } )
  let collections = pureCollections
  let collectionOption
  let dateOption
  let priceOption
  let priceOptionValue = 0
  let styleOption
  let packOption

  filterOptions.forEach(filterOption => {
    const filter = filterOption.type.replace(/\s/g, "").toLowerCase()
    const option = filterOption.filter

    if (filter === "collection") collectionOption = option
    if (filter === "onshelfdate") dateOption = option.toLowerCase()
    if (filter === "pricepoints") {
      const numberPattern = /\d+/g;
      priceOptionValue = Number(option.match( numberPattern )[0])
      priceOption = option.includes("<") ? "less" : "more"
    }
    if (filter === "articlegroup") styleOption = option
    if (filter === "merchandisingpacks") packOption = option
  })

  // filter By URL Args

  if (urlSearch) {
    const improvedUrlSearch = urlSearch
      .replace("?collection=", "")
      .toLowerCase()
      .trim()
    const requestedCollection =
      improvedUrlSearch !== "ecollection"
        ? improvedUrlSearch
        : "special edition"

    const collectionIndex = collections.findIndex(
      collection =>
        collection.name.replace("Collection", "").toLowerCase().trim() ===
        requestedCollection
    )
    const targetedCollection = collections.splice(collectionIndex, 1)
    collections.splice(0, 0, targetedCollection[0])
    collections = collections.slice()
    setCollections(collections.slice())
  }

  //   Filters by Collection
  if (collectionOption) {
    collections.forEach(collection => {
      const targetedCollection = collection.name.includes(collectionOption)
      if (targetedCollection) {
        collections = [collection]
        setCollections([collection])
      }
    })
  }

  //   Filters by Price
  if (priceOption && collections) {
    const newCollections = collections.map(collection => {
      return {
        ...collection,
        products: collection.products.filter(product => {
          if (!product.pricePoint) return false;
          return priceOption === "less"
              ? Number(product.pricePoint.name) < priceOptionValue
              : Number(product.pricePoint.name) > priceOptionValue
        }),
      }
    })

    collections = newCollections
    setCollections(newCollections)
  }
  //   Filters by date
  if (dateOption && collections) {
    const newCollections = collections.map(collection => {
      return {
        ...collection,
        products: collection.products.filter(
          product => {
            if (product.onshelfDate === null || product.onshelfDate === undefined) return false
            const shelfDate = product.onshelfDate.name
            const splitDate = shelfDate.split(".")
            const nameMonth = splitDate.length > 1 ? splitDate[1] : shelfDate
            return Number(parseInt(nameMonth)) === Number(datesInNumbers[dateOption])
          }
        ),
      }
    })

    collections = newCollections
    setCollections(newCollections)
  }
  //   Filters by packages
  if (packOption && collections) {
    const newCollections = collections.map(collection => {
      return {
        ...collection,
        products: collection.products.filter(
          product =>
            product.packages.length > 0 &&
            product.packages.filter(
              innerPackage =>
                innerPackage.name.toLowerCase() === packOption.toLowerCase()
            ).length > 0
        ),
      }
    })

    collections = newCollections
    setCollections(newCollections)
  }
  //   Filters by targetGroup
  if (styleOption && collections) {
    // console.log("hi")
    const newCollections = collections.map(collection => {
      return {
        ...collection,
        products: collection.products.filter(product => {
          return (
            product.targetGroup &&
            product.targetGroup.name.toLowerCase() === styleOption.toLowerCase()
          )
        }),
      }
    })
    // console.log(styleOption, newCollections[0].products.targetGroup)
    collections = newCollections
    setCollections(newCollections)
  }
  if (
    !collectionOption &&
    !dateOption &&
    !priceOption &&
    !styleOption &&
    !packOption
  ) {
    setCollections(pureCollections)
  }
}
export default filterProducts
