const generateCollectionsFromProducts = ({allProducts, allCollections}) => {
    let collections = [...allCollections];

    collections = collections.filter(item => item.isActive)
        .map(collection => ({...collection, products: allProducts.filter(product => product.isoGroupId === collection.id)}));

    return collections;
}

export default generateCollectionsFromProducts;
