import React, {useEffect, useState} from "react"
import {StaticQuery, graphql} from "gatsby";
import ProductStore from '../../store/product';
import {observer} from 'mobx-react';
import CollectionFilterDropdown from "../collection-filter-dropdown/collection-filter-dropdown"

const CollectionFilter = observer(({
  viewType,
  setViewType,
  setFilterOptions,
  setPrice,
}) => {
  const [opened, setOpened] = useState(false)
  useEffect(() => {
    typeof window !== "undefined" &&
      localStorage.setItem("view", JSON.stringify(viewType))
  }, [viewType])
  const packages = ProductStore.getPackages;
  const allCollections = ProductStore.getCollections;
  return (
    <StaticQuery
      query={graphql`
        {
          groups: allTargetGroupsJson {
            nodes {
              name
              id
            }
          }
        }
      `}
      render={data => {
        return (
          <div
            className={`${opened ? "h-auto lg:h-96" : "h-14"
              } relative z-20 px-4 pt-4 text-base text-black bg-primary transition-height duration-500 overflow-hidden lg:z-0 lg:pt-0 lg:text-lg 2xl:text-xl`}
          >
            <div className="flex items-start lg:pt-3.5 lg:justify-between 2xl:px-10  ">
              <div className="min-w-full sm:min-w-full lg:min-w-0 lg:w-full lg:flex lg:items-start lg:justify-between 2xl:mr-24">
                <p
                  className=" cursor-pointer relative top-0 left-0 lg:whitespace-nowrap lg:cursor-auto"
                  onClick={() => setOpened(!opened)}
                >
                  Filter By:
                </p>

                <div className="mt-9 w-full sm:flex sm:flex-wrap sm:justify-between lg:mt-0 lg:items-start lg:justify-around">
                  <CollectionFilterDropdown
                    filterOptions={[
                      {
                        name: "Merchandising Packs",
                        filters: packages.map(pack => pack.name),
                      },
                      {
                        name: "Collection",
                        filters: allCollections.filter(item => item.isActive).map(coll => coll.name),
                      },
                      {
                        name: "On Shelf Date",
                        filters: ["August", "September", "October", "November", "December", "January", "February", "March", "April"],
                      },
                      {
                        name: "Price Points",
                        filters: ["< 100.- EUR", "< 200.- EUR", "< 300.- EUR", "> 300.- EUR"],
                      },
                      {
                        name: "Article Group",
                        filters: data.groups.nodes.map(group => group.name),
                      },
                    ]}
                    opened={opened}
                    setOpened={setOpened}
                    setFilterOptions={setFilterOptions}
                    setPrice={setPrice}
                  />
                </div>
              </div>

              <div className="flex space-x-2 absolute right-4  box-content lg:space-x-0 lg:static lg:flex lg:items-center">
                <p
                  className=" whitespace-nowrap cursor-pointer lg:mr-5 lg:cursor-auto"
                  onClick={() => setOpened(!opened)}
                >
                  View By:
                </p>
                <button
                  onClick={() => setViewType("thumbnails")}
                  className={`${viewType === "thumbnails" && "border-b border-black"
                    } cursor-pointer`}
                >
                  Thumbnails
                </button>
                <span className="lg:mx-1.5">/</span>
                <button
                  onClick={() => setViewType("list")}
                  className={` ${viewType === "list" && "border-b border-black"
                    } cursor-pointer`}
                >
                  List
                </button>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
})

export default CollectionFilter
