import React, { useEffect, useState } from "react"

const CollectionFilterDropdown = ({
  filterOptions,
  opened,
  setOpened,
  setFilterOptions,
}) => {
  const [filters, setFilters] = useState([])

  useEffect(() => {
    setFilterOptions(filters)
  }, [filters, setFilterOptions])

  const handleFilter = (type, filter) => {
    function containsObject(obj, list) {
      const typeExist = list.some(elem => elem.type === obj.type)
      const filterExist = list.some(elem => elem.filter === obj.filter)

      if (filterExist) {
        const index = list.findIndex(elem => elem.filter === obj.filter)
        list.splice(index, 1)
        return setFilters([...list])
      }

      if (typeExist) {
        const filtered = list.filter(elem => elem.type !== obj.type)
        return setFilters([...filtered, obj])
      }
      return setFilters([...list, obj])
    }

    containsObject({ type, filter }, filters)
  }
  return filterOptions.map((option, i) => (
    <div key={i}>
      <button
        onClick={() => setOpened(!opened)}
        className="cursor-pointer flex flex-nowrap items-center space-x-2 text-xl lg:text-lg 2xl:text-xl"
      >
        <p className="whitespace-nowrap">{option.name}</p>
        <img className="lg:-mb-1 lg:ml-2" src="/arrow_down.svg" alt="" />
      </button>
      <div className="mt-6 mb-7 lg:mb-0">
        {option.filters.map((filter, i) => (
          <button
            key={i}
            className={`${
              filters.some(inFilter => inFilter.filter === filter)
                ? "border-b border-black"
                : ""
            } cursor-pointer mb-2 text-base block`}
            onClick={() => handleFilter(option.name, filter)}
          >
            {option.name.toLowerCase() === "merchandising packs"
              ? `${filter[0].toUpperCase()}${filter.slice(1).toLowerCase()}`
              : filter.toLowerCase() === "special edition collection"
              ? "E Collection"
              : filter}
          </button>
        ))}
      </div>
    </div>
  ))
}

export default CollectionFilterDropdown
